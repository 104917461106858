<template>
  <el-dialog
    title=""
    class="dialog"
    :visible.sync="dialogVisible"
    width="550px"
    center
    lock-scroll
    :before-close="handleClose"
  >
    <div v-if="title" class="dialog-header">
      <p class="dialog-header_title">{{ title }}</p>
      <p class="dialog-header_date">2023-06-20</p>
    </div>
    <el-form
      ref="checkCalendarForm"
      :label-position="labelPosition"
      label-width="100px"
      :model="dialogFormData"
    >
      <el-form-item
        v-for="(item, idx) in dialogFormDataItems"
        :key="idx"
        :label="item.label"
        :prop="item.prop"
        :rules="item.object.rules"
      >
        <el-input
          v-if="item.type == 'text'"
          :placeholder="item.placeholder"
          v-model="dialogFormData[item.prop]"
          :type="item.inputtype ? item.inputtype : 'text'"
          :disabled="item.disabled"
        />
        <el-radio-group
          v-else-if="item.type == 'radiogroup'"
          v-model="dialogFormData[item.prop]"
          :disabled="isBoolean(item.object.disabled) ? item.object.disabled : false"
        >
          <el-radio
            v-for="opt in item.options"
            :label="opt.id"
            :key="opt.id"
          >
            {{ opt.label }}
          </el-radio>
        </el-radio-group>
        <el-select
          v-else-if="item.type == 'select'"
          v-model="dialogFormData[item.prop]"
          :placeholder="item.placeholder"
          :style="item.object.style ? item.object.style : '{ width : 100% }'"
          :disabled="isBoolean(item.object.disabled) ? item.object.disabled : false"
          :clearable="isBoolean(item.object.clearable) ? item.object.clearable : true"
          :multiple="isBoolean(item.object.multiple) ? item.object.multiple : false"
          :filterable="isBoolean(item.object.filterable) ? item.object.filterable : false"
          :loading="item.object.loading"
          :loading-text="$t('message.loading')"
          @change="(data) => $emit('changenext', data, item)"
        >
          <el-option
            v-for="option in item.options"
            :key="option[item.object.key ? item.object.key : 'value']"
            :label="option[item.object.name ? item.object.name : 'name']"
            :value="option[item.object.value ? item.object.value : 'value']"
            :disabled="isBoolean(option.disabled) ? option.disabled : false"
          />
        </el-select>
        <el-date-picker
          v-else-if="item.type == 'daterange'"
          v-model="dialogFormData[item.prop]"
          type="daterange"
          :range-separator="item.rangeSeparator"
          :start-placeholder="item.startPlaceholder"
          :end-placeholder="item.endPlaceholder">
        </el-date-picker>
        <div v-else class="dialog-form_text">{{ item.value }}</div>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">{{$t('button.cancel')}}</el-button>
      <el-button type="primary" @click="confirmHandler">{{$t('button.confirm')}}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import moment from 'moment'
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    dialogFormData: {
      type: Object,
      default: () => {}
    },
    dialogFormDataItems: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: ''
    },
    date: {
      type: String,
      default: ''
    },
    labelPosition: {
      type: String,
      default: 'top'
    }
  },
  data () {
    return {}
  },
  methods: {
    handleClose () {
      this.$emit('update:dialogVisible', false)
    },
    isBoolean(data) {
      if (data || typeof data === 'boolean') return true
      else return false
    },
    confirmHandler () {
      this.$refs['checkCalendarForm'].validate((valid) => {
        if (valid) {
          const formdata = JSON.parse(JSON.stringify(this.dialogFormData))
          if (Object.hasOwn(this.dialogFormData, 'date')) {
            const { date } = this.dialogFormData
            if (date.length > 0) {
              formdata.start_date = moment(date[0]).format('YYYY-MM-DD')
              formdata.end_date = moment(date[1]).format('YYYY-MM-DD')
              delete formdata.date
            }
          }
          this.$emit('submit', formdata)
        } else {
          console.log('error submit!!')
          return false
        }
      });
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding: 0 20px 0 20px !important;
}
.dialog {
  color: #606266;
  .dialog-header {
    margin-bottom: 10px;
    text-align: center;
    &_title {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 7px;
    }
    &_date {
      font-size: 14px;
    }
  }
}
</style>
<template>
  <el-card>
    <div class="housingstatus-header housingstatus-flex">
      <div class="housingstatus-flex">
        <el-button type="primary" style="margin-right: 10px;" @click="changePriceHandler">
          {{$t('form.rest.changePrice')}}
        </el-button>
        <el-button plain @click="prevHandler">
          <i class="el-icon-arrow-left"></i>
          <span>{{ $t('form.rest.prevWeek') }}</span>
        </el-button>
        <div class="housingstatus-header_date">{{ startDate }} ~ {{ endDate }}</div>
        <el-button plain @click="nextHandler">
          <span>{{ $t('form.rest.nextWeek') }}</span>
          <i class="el-icon-arrow-right"></i>
        </el-button>
      </div>
    </div>
    <el-table
      ref="calendarTable"
      v-loading="loading"
      :data="tableData"
      class="housingstatus-table"
    >
      <template v-for="(item, idx) in tableDataItems">
        <el-table-column
          v-if="idx < 2"
          fixed
          :label="item.label"
          :prop="item.prop"
          :width="item.width"
          :key="item.label"
        >
          <template slot-scope="scope">
            <div v-if="item.prop == 'roomtype'" >
              <div>{{ scope.row['rest_room_name'] }}</div>
              <div>{{ scope.row['rest_type_name'] }}</div>
            </div>
            <div v-else>
              {{ scope.row[item.prop] }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          v-else
          :label="item.label"
          :width="item.width"
          :key="idx"
        >
          <template v-if="scope.row.calendars && scope.row.calendars.length > 0 && scope.row.calendars[idx - 2]" slot-scope="scope">
           <div class="housingstatus-cell" @click="() => changeCalendarHandler(scope.row, scope.row.calendars[idx - 2])">
            <div>${{ scope.row.calendars[idx - 2].price }}</div>
            <div :class="`text-${scope.row.calendars[idx - 2].classname}`">{{ scope.row.calendars[idx - 2].status }}</div>
           </div>
          </template>
        </el-table-column>
      </template>
    </el-table>
    <CheckDialog
      v-if="dialogVisible"
      :title="title"
      :date="date"
      :dialogVisible.sync="dialogVisible"
      :dialogFormData="dialogFormData"
      :dialogFormDataItems="dialogFormDataItems"
      :labelPosition="labelPosition"
      @changenext="changeNextHandler"
      @submit="submitHandler"
    />
  </el-card>
</template>

<script>
import CheckDialog from '../../../../components/checkDialog.vue'
import {
  getRestCalendarList,
  getRestList,
  getRestTypeList,
  updateRoomsPrice,
  updateRestsCalendar
} from '@/services/rests'
import moment from 'moment'
import constant from '@/utils/constant.js'
import loading from '@/utils/loading.json'
import jsonData from './jsonData.js'
import utils from '@/utils/Utils'
import _ from 'lodash'
export default {
  components: {
    CheckDialog
  },
  data () {
    return {
      startDate: '',
      endDate: '',
      title: '',
      date: '',
      loading: false,
      dialogVisible: false,
      tableData: [],
      labelPosition: 'top',
      tableDataItems: _.cloneDeep(jsonData.tableDataItems),
      dialogFormData: {
        status: '',
        extra_price: ''
      },
      dialogFormDataItems: _.cloneDeep(jsonData.dialogFormDataItems),
      dialogType: 'changeprice'
    }
  },
  created () {
    this.startDate = moment().format('YYYY-MM-DD')
    this.endDate = moment().add(6, 'days').format('YYYY-MM-DD')
    this.initData()
  },
  methods: {
    async initData () {
      this.$store.state.app.loading = this.$loading(loading)
      this.showAllDate()
      try {
        const res = await getRestCalendarList({rest_date_between: this.startDate + 'to' + this.endDate})
        if (res.status == 200 && res.data.calendars) {
          const { calendars } = res.data
          const statusArr = constant.CALENDAR_WEEKEDN.ROOM_STATUS
          const classList = constant.CALENDAR_WEEKEDN.ROOM_STATUS_CLSSNAMES
          if (calendars[0].calendars && calendars[0].calendars.length > 0) {
            this.tableDataItems = _.cloneDeep(jsonData.tableDataItems)
            calendars.forEach((c) => {
              c.calendars.forEach((d) => {
                d.classname = 'black'
                const idx = statusArr.findIndex((l) => this.$t(`form.rest.${l}`) == d.status)
                if (idx >= 0) {
                  d.classname = classList[idx]
                }
              })
            })
            calendars[0].calendars.forEach((d) => {
              this.tableDataItems.push({
                prop: '',
                label: d.rest_date + ' ' + this.$t(`form.rest.${constant.CALENDAR_WEEKEDN.WEEKDAY[d.rest_week]}`),
                width: '150'
              })
            })
            this.tableData = calendars
          } 
        }
      } catch (error) {
        
      }
      this.$store.state.app.loading.close()
    },
    showAllDate () {
      for (let i = 0; i < 7; i ++) {
        let date = moment(this.startDate).add(i, 'days')
        let day = date.day()
        this.tableDataItems.push({
          prop: '',
          label: date.format('YYYY-MM-DD') + ' ' + this.$t(`form.rest.${constant.CALENDAR_WEEKEDN.WEEKDAY[day]}`),
          width: '150'
        })
      }
    },
    prevHandler () {
      this.endDate = this.startDate
      this.startDate = moment(this.endDate).subtract(6, 'days').format('YYYY-MM-DD')
      this.initData()
    },
    nextHandler () {
      this.startDate = this.endDate
      this.endDate = moment(this.startDate).add(6, 'days').format('YYYY-MM-DD')
      this.initData()
    },
    changeCalendarHandler (row, calendarData) {
      const statusArr = constant.CALENDAR_WEEKEDN.ROOM_STATUS
      this.title = row.rest_name + row.rest_type_name + ' - ' + row.rest_room_name
      this.date = calendarData.rest_date
      this.labelPosition = 'top'
      this.dialogType = 'changestatus'
      this.dialogFormDataItems = _.cloneDeep(jsonData.dialogFormDataItems)
      const idx = statusArr.findIndex((l) => this.$t(`form.rest.${l}`) == calendarData.status)
      this.dialogFormData = {
        status: idx >= 0 ? statusArr[idx]: '',
        rest_room_id: row.rest_room_id,
        rest_date: calendarData.rest_date
      }
      // if (idx >= 0 && statusArr[idx] == 'to_check_in') {
      //   this.dialogFormDataItems[1].object.disabled = true
      // }
      let arr = []
      statusArr.forEach((d) => {
        arr.push({
          id: d,
          label: this.$t(`form.rest.${d}`)
        })
      })
      this.dialogFormDataItems[1].options = arr
      this.dialogVisible = true
    },
    async changePriceHandler () {
      this.$store.state.app.loading = this.$loading(loading)
      const params = {page: 1, per_page: 1000}
      this.labelPosition = 'right'
      this.dialogType = 'changeprice'
      this.title = this.$t('form.rest.changePrice')
      this.dialogFormDataItems = _.cloneDeep(jsonData.dialogFormDataItem1s)
      this.dialogFormData = {
        price: '',
        start_date: '',
        end_date: '',
        date: '',
        rest_type_id: ''
      }
      const restRes = await getRestList(params)
      if (restRes.status == 200) {
        const { arr } = utils.formatTableData(restRes.data.objects)
        this.dialogFormDataItems[0].options = arr
      }
      this.dialogVisible = true
      this.$store.state.app.loading.close()
    },
    async changeNextHandler(data, item) {
      const params = {page: 1, per_page: 1000, rest_id_eq: data}
      if (item.prop == 'rest_id') {
        this.dialogFormDataItems[1].object.loading = true
        this.dialogFormDataItems[1].object.disabled = false
        const res = await getRestTypeList(params)
        if (res.status == 200) {
          const { arr } = utils.formatTableData(res.data.objects)
          this.dialogFormDataItems[1].options = arr
        }
        this.dialogFormDataItems[1].object.loading = false
      }
    },
    submitHandler (form) {
      this.$store.state.app.loading = this.$loading(loading)
      let api = () => updateRestsCalendar(form)
      if (this.dialogType == 'changeprice') {
        const id = form.rest_type_id
        form.price = Number(form.price)
        delete form.rest_id
        delete form.rest_type_id
        api = () => updateRoomsPrice(id, form)
      }
      api().then((res) => {
        if (res.status >= 200 && res.status < 300) {
          this.$message({
            type: 'success',
            message: this.$t('message.operateSuccess')
          })
          this.initData()
        }
      }).finally(() => {
        this.dialogVisible = false
        this.$store.state.app.loading.close()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.housingstatus-flex {
  display: flex;
  align-items: center;
}
.housingstatus-header {
  justify-content: space-between;
  margin-bottom: 20px;
  &_date {
    width: 228px;
    text-align: center;
    font-size: 14px;
  }
}
::v-deep .el-table {
  th.el-table__cell {
    background-color: #F2F3F5;
    color: #606266;
    font-weight: 600;
  }
  .cell {
    color: #606266;
    padding: 0 16px;
    .text-red {
      color: #ff6b6b;
    }
    .text-blue {
      color: #409eff;
    }
    .text-yellow {
      color: #fbc31f;
    }
    .text-green {
      color: #4cbf4a;
    }
    .housingstatus-cell {
      cursor: pointer;
      padding: 9px 0;
    }
  }
}
</style>

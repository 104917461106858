import i18n from '@/i18n/index'
import constant from '@/utils/constant.js'
export default {
  tableDataItems: [
    {
      prop: 'rest_name',
      label: i18n.t('form.rest.rest'),
      width: '120'
    },
    {
      prop: 'roomtype',
      label: i18n.t('form.rest.roomAndType'),
      width: '150'
    }
  ],
  dialogFormDataItems: [
    {
      type: 'normaltext',
      prop: '',
      label: i18n.t('form.order.order'),
      value: '-',
      object: {}
    },
    {
      type: 'radiogroup',
      label: i18n.t('form.rest.changeRoomStatus'),
      prop: 'status',
      name: "radiogroup",
      options: [],
      object: {
        key: 'id',
        value: 'id'
      }
    }
  ],
  dialogFormDataItem1s: [
    {
      type: 'select',
      name: 'select',
      prop: 'rest_id',
      options: [],
      label: i18n.t('form.rest.restList'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      object: {
        key: 'id',
        value: 'id',
        rules: [
          { required: true, message: i18n.t('text.require', { name: i18n.t('form.rest.restList') }) }
        ]
      }
    },
    {
      type: 'select',
      name: 'select',
      prop: 'rest_type_id',
      options: [],
      label: i18n.t('menu.roomList'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      object: {
        key: 'id',
        value: 'id',
        disabled: true,
        loading: false,
        rules: [
          { required: true, message: i18n.t('text.require', { name: i18n.t('menu.roomList') }) }
        ]
      }
    },
    {
      type: 'daterange',
      name: 'daterange',
      prop: 'date',
      label: i18n.t('fooyoDashboard.dateRange'),
      startPlaceholder: i18n.t('placeholder.startDate'),
      endPlaceholder: i18n.t('placeholder.endDate'),
      angeSeparator: 'To',
      object: {
        rules: [
          { required: true, message: i18n.t('text.require', { name: i18n.t('fooyoDashboard.dateRange') }) }
        ]
      }
    },
    {
      type: 'text',
      name: 'input',
      inputtype: 'number',
      prop: 'price',
      label: i18n.t('form.rest.changePrice'),
      placeholder: i18n.t('placeholder.defaultInput'),
      object: {
        disabled: false,
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON,
        rules: [
          { required: true, message: i18n.t('text.require', { name: i18n.t('form.rest.changePrice') }) }
        ]
      }
    }
  ]
}
